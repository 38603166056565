import Navbar from "../components/Navbar";
import NavBarMobile from "../components/NavBarMobile";
import CmsPage from "../components/CmsPage";

import { loadPage } from "../lib/api-lib";

function Page({ structure }) {
  return (
    <div>
      <div id="popup" className={`
        fixed bottom-0 w-full flex flex-col md:flex-row gap-2 p-2 justify-center items-center !bg-red-300 text-red-900 z-[101] font-normal`
      }>
        Gentile visitatore, a causa del prolungarsi di un guasto tecnico, puoi contattarci al seguente numero di telefono:
        <b>+39 351.6500285</b>
      </div>
      <Navbar navigation={structure.navigation} />
      <NavBarMobile navigation={structure.navigation} />
      <CmsPage structure={structure} />
    </div>
  );
}

export async function getStaticProps(context) {
  // console.log(context)
  const data = await loadPage() || false;
  if(data == false) {
    return {
      notFound: true
    };
  } else {
    return {
      props: {
        structure: data,
      },
      revalidate: 10,
    };
  }
}

export default Page;
